<!--
 * @Autor: syq
 * @Date: 2021-07-13 14:06:11
-->
<template>
  <div id="shopdetails">
    <div class="top">
      <details-head :info="goodsInfo" :currentSkuItem="currentSkuItem" />
      <details-center :info="goodsInfo" :currentSkuItem="currentSkuItem" />
    </div>
    <div class="footer">
      <details-footer :info="goodsInfo"  :currentSkuItem="currentSkuItem" @currentSkuItemChange="currentSkuItemChange" />
    </div>
    <van-loading
     size="24px"
     v-show="shopdetail"
     vertical
    >
      加载中...
    </van-loading>
  </div>
</template>

<script>
import DetailsHead from "./components/DetailHead.vue";
import DetailsCenter from "./components/DetailCenter.vue";
import DetailsFooter from "./components/DetailFooter.vue";
import { customerCode } from "./service";
import {Toast} from "vant"
export default {
  components: { DetailsHead, DetailsCenter, DetailsFooter },
  data() {
    return {
      goodsInfo: {},
      currentSkuItem:{},
      shopdetail: false,
    };
  },

  methods: {
    async customerCode() {
      this.shopdetail = true;
      const res = await customerCode(
        this.$route.query.itemCode,
        this.$route.query.storeCode
      );
      if (res.status === "0") {
        this.goodsInfo = res.data;
        console.log(this.goodsInfo,'this.goodsInfo');
        // 初始化sku选择第一个
        this.currentSkuItem = res.data.skuList[0]
        this.shopdetail = false;
      } else {
        this.shopdetail = false;
        Toast.fail(res.msg);
      }
    },
    currentSkuItemChange(item){
       this.currentSkuItem = item
    }

  },
  created() {
    this.customerCode();
  },
};
</script>

<style lang="less">
#shopdetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: @BackgroundColor;
  .top{
     flex: 1 1 0; 
     height: 100%;
     overflow: auto; 
  }
  .footer{
    width: 100%;
    height: 50px;
  }
}
</style>
