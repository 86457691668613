<template>
  <div id="activity">
    <div class="collar-head">
      <p class="collar-floor">活动</p>
      <p class="collar-title">正在参与两个活动</p>
    </div>
    <div class="coupon-card">
      <div class="coupon-border">
        <div class="activity-title">
          <button>满减</button>
          <p>满100-10元；满200-25元；满300-40元；满500-80元</p>
        </div>
      </div>
    </div>
    <div class="floor">
      <button>限购</button>
      <p>该商品最多购买5件</p>
    </div>
    <div class="quit">
      <button class="btn" @click="remove">取消</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    remove() {
      this.msg = false;
    },
  },
};
</script>

<style lang="less">
#activity {
  width: 100%;
  line-height: 10px;
  background-color: #f7f8fa;
  .collar-head {
    display: flex;
    font-size: 16px;
    margin-bottom: -8px;
  }
  .collar-floor {
    margin-left: 12px;
  }
  .collar-title {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 13px;
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #555555;
    text-align: left;
  }
  .coupon-card {
    margin: 0px 12px 12px;
    background-color: #fff;
    border-radius: 8px;
    .coupon-border {
      border: 1px solid #ebedf0;
      margin-top: 12px;
      .activity-title {
        position: relative;
        height: 73px;
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        border-color: rgba(242, 242, 242, 1);
        color: #666666;
        text-align: left;
        line-height: 28px;
        p {
          position: absolute;
          left: 60px;
          top: 3px;
          font-family: "微软雅黑", sans-serif;
          font-weight: 400;
          font-style: normal;
          color: #7f7f7f;
          text-align: left;
          line-height: 22px;
          font-size: 13px;
        }
        button {
          position: absolute;
          top: 17px;
          left: 15px;
          background-color: #ec808d;
          width: 38px;
          height: 20px;
          border: 0;
          font-size: 11px;
          color: #ffffff;
          line-height: 19px;
        }
      }
    }
  }
  .floor {
    margin: 0px 12px 12px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ebedf0;
    margin-top: -5px;
    height: 52px;
    position: relative;
    button {
      position: absolute;
      top: 11px;
      left: 15px;
      background-color: #ec808d;
      width: 38px;
      height: 20px;
      border: 0;
      font-size: 11px;
      color: #ffffff;
      line-height: 19px;
    }
    p {
      position: absolute;
      left: 60px;
      top: -2px;
      font-family: "微软雅黑", sans-serif;
      font-weight: 400;
      color: #7f7f7f;
      line-height: 22px;
      font-size: 13px;
    }
  }
  .quit {
    .btn {
      margin-top: 153px;
      display: block;
      width: 100%;
      padding: 0;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
      background: #fff;
      border: none;
    }
  }
}
</style>
