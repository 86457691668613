<template>
  <div id="de-head">
    <!--轮播图-->
    <div class="swiper_warp">
      <van-swipe
        @change="onChange"
        class="swipe"
        indicator-color="#0095da"
        :loop="false"
        initial-swipe="0"
      >
        <van-swipe-item v-for="(i, index) in imgList" :key="index">
          <img v-if="i.type === 1" class="swipeimg" v-lazy="i.url" />
          <video
            v-if="i.type === 2"
            controls="controls"
            class="swipeimg"
            :src="i.url"
          ></video>
        </van-swipe-item>
      </van-swipe>
      <div class="custom-indicator">
        {{ `${current + 1}/${imgList.length}` }}
      </div>
    </div>
    <!-- 商品 -->
    <div class="details-title">
      <div class="detail-title-left">
        <p class="title">{{ info.name || "" }}</p>
        <div class="price">
          <span class="price-left">¥{{ itemProprice || itemPrice }}</span>
          <span class="price-right"> {{ itemProprice && itemPrice }}</span>
        </div>
      </div>
      <!-- <div class="detail-title-right">
        <button class="share" @click="share">
          <van-icon name="share-o" />
          分享
        </button>
      </div> -->
    </div>
    <!-- 分享 -->
    <div v-show="show">
      <van-share-sheet
        v-model="showShare"
        :options="options"
        @select="onSelect"
      />
    </div>
    <!-- 优惠券 -->
    <!-- <div class="collar"> -->
    <!-- <span class="collar-title">领卷：</span> -->
    <!-- <span class="collor-price">满300元95折</span> -->
    <!-- <span class="collor-price">无</span> -->
    <!-- <span>
        <van-icon class="icon" @click="col" name="arrow" />
      </span> -->
    <!-- </div> -->
    <van-popup v-model="collar" position="bottom" :style="{ height: '60%' }">
      <Collar :message="msg" />
    </van-popup>
    <!-- 活动 -->
    <div class="activity" v-if="info.activityList && info.activityList.length > 0">
      <p class="activity-title">活动：</p>
      <div class="activity-content">
        <div
          class="activity-floor"
          v-for="(item, index) in info.activityList"
          :key="index"
        >
          <!-- <p>
          <span class="discount">无</span>
        </p> -->
          <div class="activity-gift" v-if="item.activityType === 1">
            <button class="discount-btn">
              {{
                handleTejiaPrice(
                  item.activityParameterResDto.PROMOTION_RULE,
                  item.templateName
                )[0]
              }}
            </button>
            <div class="activity-gift-item">
              <span class="discount">
                {{
                  handleTejiaPrice(
                    item.activityParameterResDto.PROMOTION_RULE,
                    item.templateName
                  )[1]
                }};
              </span>
            </div>
          </div>
          <div class="activity-gift" v-if="item.activityType === 2">
            <button class="discount-btn">满减</button>
            <div class="activity-gift-item">
              <span
                class="discount"
                v-for="(i, k) in item.activityParameterResDto.PROMOTION_RULE
                  .values"
                :key="k"
              >
                {{ getManjianActivityUnieIs12(item) ? "" : "每" }}满{{ i.range
                }}{{ getManjianActivityUnieIs13(item) ? "元" : "件" }}减{{
                  i.value
                }}元;
              </span>
            </div>
          </div>
          <div class="activity-discount" v-if="item.activityType === 8">
            <div class="activity-gift-item">
              <button class="purchase-btn">满赠</button>
              <span
                class="purchase"
                v-for="(i, k) in item.activityParameterResDto.PROMOTION_RULE
                  .values"
                :key="k"
              >
                {{ getManjianActivityUnieIs12(item) ? "" : "每" }}满{{ i.range
                }}{{ getManjianActivityUnieIs13(item) ? "元" : "件" }}可选择{{
                  i.number
                }}件赠品;</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <span><van-icon class="icon" name="arrow" @click="life" /></span> -->
    </div>
    <van-popup v-model="acvity" position="bottom" :style="{ height: '60%' }">
      <Activity />
    </van-popup>
  </div>
</template>

<script>
// import libaiLogo from '../../../assets/libailogo.png'
import Activity from "./activity.vue";
import Collar from "./collar.vue";
import { Toast } from "vant";
export default {
  components: { Collar, Activity },
  props: {
    info: Object,
    currentSkuItem: Object,
  },
  data() {
    return {
      detailslist: {},
      acvitylist: this.info.activityList,
      acvity: false,
      msg: false,
      collar: false,
      showShare: false,
      options: [
        [
          { name: "微信", icon: "wechat" },
          { name: "微博", icon: "weibo" },
          { name: "复制链接", icon: "link" },
          { name: "分享海报", icon: "poster" },
        ],
        [
          { name: "二维码", icon: "qrcode" },
          { name: "分享海报", icon: "poster" },
        ],
      ],
      show: false,
      current: 0,
    };
  },
  computed: {
    // 轮播图
    imgList: function () {
      // type 1图片 2视频
      const { mediaList, skuList } = this.info;
      const arr = [];
      Array.isArray(mediaList) &&
        mediaList.length &&
        mediaList.forEach((i) => {
          arr.push({
            url: i.url,
            type: i.type,
            name: i.name,
          });
        });
      Array.isArray(skuList) &&
        skuList.forEach((i) => {
          i?.mediaList &&
            i.mediaList.forEach((j) => {
              arr.push({
                url: j.url,
                type: j.type,
                name: j.name,
              });
            });
        });
      return arr;
    },
    //
    itemProprice: function () {
      if (this.currentSkuItem) {
        const { proPrice } = this.currentSkuItem;
        return proPrice ? proPrice.toFixed(2) : "";
      }
    },
    itemPrice: function () {
      return this.currentSkuItem?.salePrice
        ? this.currentSkuItem?.salePrice?.toFixed(2)
        : "0.00";
    },
  },
  methods: {
    handleTejiaPrice({ name, values }, template) {
      switch (template) {
        case "限时折扣":
          return ["折扣", `单件商品享${values[0].value}折`];
          break;
        case "限时减价":
          return ["减价", `单件商品减${values[0].value}元`];
          break;
        case "限时特价":
          return ["特价", `单件商品特价${values[0].value}元`];
          break;
      }
    },
    // 获取满减活动12类型
    getManjianActivityUnieIs12(active) {
      return (
        active.activityParameterResDto.PROMOTION_RULE.type == 1 ||
        active.activityParameterResDto.PROMOTION_RULE.type == 2
      );
    },
    // 获取满减活动13类型
    getManjianActivityUnieIs13(active) {
      return (
        active.activityParameterResDto.PROMOTION_RULE.type == 1 ||
        active.activityParameterResDto.PROMOTION_RULE.type == 3
      );
    },
    life() {
      this.acvity = true;
    },
    col() {
      this.msg = true;
      this.collar = true;
    },
    onChange(index) {
      this.current = index;
    },
    share() {
      this.show = true;
      this.showShare = true;
    },
    onSelect(option) {
      Toast(option.name);
    },
  },
  mounted() {},
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
</style>
<style lang="less" scoped>
#de-head {
  .van-swipe-item {
    background: #fff;
  }
  .swiper_warp {
    position: relative;
    .custom-indicator {
      position: absolute;
      right: 24px;
      bottom: 20px;
      width: 38px;
      padding: 4px 5px;
      font-size: 12px;
      text-align: center;
      border-radius: 50px;
      background: rgba(77, 77, 77, 0.8);
      color: #fff;
    }
  }
  .van-swipe {
    .swipeimg {
      width: 100%;
      height: 375px;
    }
  }
  .details-title {
    width: 100%;
    display: flex;
    border-top: 2px solid #fff;
    background: #fff;
    .detail-title-left {
      flex: 1;
      padding-left: 16px;
      padding-top: 16px;
      .title {
        width: 285px;
        height: 48px;
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #000;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .price {
        margin: 8px 0 16px 0;
        width: 100%;
        height: 24px;
        line-height: 24px;
        font-size: 20px;
        font-weight: 500;
        color: #ff3228;
      }
    }
    .detail-title-right {
      width: 65px;
    }
    .share {
      position: absolute;
      right: 0px;
      width: 65px;
      height: 30px;
      border-radius: 17px;
      font-size: 12px;
      color: #ffffff;
      border: 0;
      background-color: rgba(255, 169, 76, 1);
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .collar {
    margin-top: 8px;
    padding: 0 16px;
    width: 100%;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    .collar-title {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: #96979b;
      text-align: left;
    }
    .collor-price {
      font-size: 11px;
      color: #ec808d;
      margin-left: 15px;
    }
    .icon {
      position: absolute;
      right: 5px;
      color: #c5c1c1;
      top: 12px;
      font-size: 20px;
    }
  }
  .activity {
    background-color: #fff;
    width: 100%;
    margin-top: 8px;
    padding: 5px 16px 10px;
    display: flex;
    align-items: center;
    .activity-title {
      font-size: 14px;
      flex-basis: 42px;
      color: #96979b;
      text-align: left;
    }
    .activity-content {
      flex: 1;
    }
    .activity-floor {
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;

      .activity-gift {
        display: flex;
        width: 100%;
        flex-direction: row;
        .activity-gift-item {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }
        button {
          background: #fff1f5;
          border: 1px solid #ff3228;
          border-radius: 8px;
          width: 40px;
          height: 20px;
          border: 0;
          font-size: 11px;
          color: #ff3228;
          line-height: 20px;
        }
        .discount {
          display: flex;
          font-weight: 400;
          text-align: left;
          font-size: 11px;
          line-height: 20px;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        // .discount-btn {
        // }
      }
      .activity-discount {
        margin-top: 2px;
        display: flex;
        flex-direction: row;
        display: flex;
        width: 100%;
        .activity-gift-item {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }
        .purchase {
          display: flex;
          font-weight: 400;
          text-align: left;
          font-size: 11px;
          line-height: 20px;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .purchase-btn {
          background: #fff1f5;
          border: 1px solid #ff3228;
          border-radius: 8px;
          width: 40px;
          height: 20px;
          border: 0;
          font-size: 11px;
          color: #ff3228;
          line-height: 20px;
        }
      }
    }
  }
  .icon {
    position: absolute;
    right: 5px;
    color: #c5c1c1;
    top: 15px;
    font-size: 30px;
  }

  .price-left {
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
  }

  .price-right {
    font-family: Helvetica, Helvetica-Regular;
    font-weight: 400;
    text-decoration: line-through;
    text-align: left;
    color: #96979b;
    line-height: 40px;
    font-size: 14px;
    margin-left: 7px;
  }
}
</style>
