<template>
  <div id="shopcart">
    <!-- 商品 -->
    <div class="top">
      <div class="left">
        <img :src="itemImage" alt="" />
      </div>
      <div class="right">
        <div class="right-content">
          <p class="right-name">{{ itemname }}</p>
          <p class="right-price">{{ `¥${itemPrice}` }}</p>
        </div>
      </div>
    </div>
    <!-- 数量 -->
    <div class="stepper">
      <van-field name="stepper" label="选择购买数量">
        <template #input>
          <van-stepper v-model="stepper" />
        </template>
      </van-field>
    </div>
    <!-- 规格 -->
    <div class="choose-btn">
      <div class="choose-title">规格：</div>
      <div class="choose-item">
        <a
          v-for="s in itempropertyName"
          :key="s.id"
          :class="{ active: s.id === (selectedSkuId || defaultSelectedSku) }"
          @click="() => selectSkuItem(s)"
        >
          {{ s.name }}
        </a>
      </div>
    </div>

    <div class="f_h"></div>
    <div class="footer">
      <div class="content">
        <button class="footer-btn1" @click="handleCancel">取消</button>
        <button
          :class="Stock ? 'footer-btn3' : 'footer-btn2'"
          :disabled="Stock"
          @click="handleOk"
        >
          {{ Stock ? "已售罄" : "确认" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    skuList: Array,
    add: Function,
    close: Function,
    storeCode: String,
  },
  data() {
    return {
      skuItem: this.skuList[0] || null,
      selectedSkuId: undefined,
      selectedPropertyId: undefined,
      stepper: 1,
      skuNum: "",
      Stock: false,
    };
  },
  computed: {
    itemImage: function () {
      const { mediaList } = this.skuItem;
      return mediaList
        ? mediaList[0]?.url
        : "http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D";
    },

    itemPrice: function () {
      const { salePrice } = this.skuItem;
      return salePrice?.toFixed(2) ?? 0;
    },

    itemname: function () {
      const { name } = this.skuItem;
      return name ?? 0;
    },

    itempropertyName: function () {
      const arr = []
      Array.isArray(this.skuList)&&this.skuList.forEach(i=>{
           if(i?.propertyValueList&&Array.isArray(i?.propertyValueList)){
             let item = '';
             i.propertyValueList.forEach(j=>{
               if(j.propertyType === 2){
                 item = item + `${j.value} `
               }
             })
             arr.push({id:i.id,name:item})
           }
      })

      return arr
    },

    itemProperty: function () {
      const { propertyValueList } = this.skuItem;
      return (
        propertyValueList &&
        propertyValueList.filter((i) => i.propertyType === 2)
      );
    },

    defaultSelectedSku: function () {
      const { propertyValueList } = this.skuItem;
      return (
        propertyValueList &&
        propertyValueList.filter((i) => i.propertyType === 2)[0].id
      );
    },
    
    defaultSelectedProperty: function () {
      const { propertyValueList } = this.skuItem;
      return (
        propertyValueList &&
        propertyValueList.filter((i) => i.propertyType === 2)[0].id
      );
    },
  },
  methods: {
    sukstock() {
      if ((this.skuList.lenght = 1 && this.skuList[0].stock == 0)) {
        this.Stock = true;
      }
    },
    selectSkuItem(item) {
      this.skuItem = this.skuList.find(i=>i.id===item.id);
      this.selectedSkuId = item.id;
    },
    selectPropertyItem(item) {
      this.selectedPropertyId = item.id;
    },
    handleCancel() {
      this.$emit("close", false);
    },
    // buyerCode:string 买家code
    // cartType	integer($int32) 购物车类型(默认0-普通)
    // channelId	integer($int32) 渠道id
    // classCode	string 品类编码
    // isChoice	integer($int32) 是否勾选 1 勾选 0取消
    // itemCode	string 商品code
    // packingUnit	string 装箱单位
    // sellerCode	string 卖家code
    // skuCode	string sku编码
    // skuQuantity	integer($int32) sku数量
    // storeCode	string 店铺编码
    // taxRate	string 税率
    // taxRateCode	string 税率编码
    handleOk() {
      const params = {
        storeCode: this.storeCode,
        sellerCode: "1",
        cartType: 0,
        classCode: this.skuItem.classCode,
        isChoice: 1,
        itemCode: this.skuItem.itemCode,
        // packingUnit:''
        skuCode: this.skuItem.skuCode,
        skuQuantity: this.stepper,
      };
      this.$emit("add", params);
    },
  },
  mounted() {
    this.sukstock();
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
</style>
<style lang="less">
#shopcart {
  width: 100%;
  .top {
    width: 100%;
    display: flex;
    .left {
      width: 80px;
      padding: 10px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        border: 1px solid #e9e9e9;
      }
    }
    .right {
      flex: 1;
    }
    .right-content {
      width: 100%;
      padding-left: 22px;
      padding-right: 8px;
      .right-name {
        color: #000;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      p {
        font-size: 14px;
        font-weight: 400;

        color: #000;
      }

      .right-price {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: #ff3228;
        font-size: 14px;
      }
    }
  }
  .van-cell {
    padding-right: 0;
  }
  .stepper {
    .van-field__value {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }
  }
  .choose-btn {
    width: 100%;
    .choose-title {
      width: 100%;
      padding: 0 0 8px 15px;
      font-size: 14px;
      font-weight: 400;
      color: #555555;
    }
    .choose-item {
      width: 100%;
      padding: 0 0 8px 15px;
      display: flex;
      a {
        margin: 0 8px 0 0;
        padding: 4px 8px;
        font-size: 12px;
        border: 1px solid #ccc;
      }
      .active {
        border-color: red;
      }
    }
  }

  .f_h {
    height: 70px;
  }
  .footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    .content {
      display: flex;
      justify-content: space-around;
      padding: 0 13px 20px;
      button {
        color: #ffffff;
        font-weight: 700;
        font-size: 13px;
        width: 50%;
        height: 60px;
        border: 0;
      }
      .footer-btn1 {
        width: 160px;
        height: 46px;
        border: 1px solid #0095da;
        border-radius: 12px;
        background: #fff;
        color: #0095da;
      }
      .footer-btn2 {
        width: 160px;
        height: 46px;
        border: 1px solid #0095da;
        border-radius: 12px;
        background: #0095da;
        color: #fff;
      }
      .footer-btn3 {
        width: 160px;
        height: 46px;
        border: 1px solid #a7a7a7;
        border-radius: 12px;
        background-color: #a7a7a7;
      }
    }
  }

  .van-stepper__input {
    font-family: "Montserrat", sans-serif;
  }
}
</style>
