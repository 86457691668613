/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";
import { stringify } from "qs";

//根据查询字段列表查询商品
const queryMarketCount = async (params) =>
  request({
    url: `/cartLine/getCartCount?${stringify(params)}`,
    method: "get",
  });

// 获取前端类目树父节点集合
const customerCode = async (skuCode, storeCode) =>
  request({
    url: `/item/${storeCode}/${skuCode}`,
    method: "get",
  });

const addGoodsToMarket = async (params) =>
  request({
    url: `/cartLine`,
    method: "post",
    data: params,
  });
const buyNow = async (params) =>
  request({
    url: `/cartLine/buyNow`,
    method: "post",
    data: params,
  });
  //多件赠品加入购物车
const BatchAddCartLine = async (params) =>
  request({
    url:`/cartLine/batchAddCartLine`,
    method:'post',
    data:params
  });
export { customerCode, addGoodsToMarket, queryMarketCount,buyNow ,BatchAddCartLine};
