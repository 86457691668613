<template>
  <div id="collar">
    <div class="collar-head">
      <p class="collar-floor">领卷</p>
      <p class="collar-title">共3张优惠券可领</p>
    </div>
    <div class="coupon-card">
      <div
        class="coupon-border"
        v-for="(item, index) in couponlist"
        :key="index"
      >
        <div class="coupon-content">
          <div class="coupon-head">
            <p class="coupon-amount">
              ￥
              <span>{{ item.price }}</span
              >
            </p>
            <p class="coupon-coudition">{{ item.coudition }}</p>
          </div>
          <div class="coupon-body">
            <p class="coupon-name">{{ item.name }}</p>
            <p class="coupon-time">{{ item.time }}</p>
            <div class="coupon-btn"><button>立即使用</button></div>
          </div>
        </div>
      </div>
    </div>
    <div class="quit">
      <button class="btn" @click="remove">取消</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      couponlist: [
        {
          price: "1.5",
          coudition: "满100可用",
          name: "货采节满减券",
          time: "2017.03.10 - 2017.12.30",
          description: "查看详情",
        },
        {
          price: "1.5",
          coudition: "满100可用",
          name: "货采节满减券",
          time: "2017.03.10 - 2017.12.30",
          description: "查看详情",
        },
        {
          price: "1.5",
          coudition: "满100可用",
          name: "货采节满减券",
          time: "2017.03.10 - 2017.12.30",
          description: "查看详情",
        },
      ],
    };
  },
  methods: {
    remove() {
      this.msg = false;
    },
  },
};
</script>

<style lang="less">
#collar {
  width: 100%;
  line-height: 10px;
  background-color: #f7f8fa;
  .collar-head {
    display: flex;
    font-size: 16px;
    margin-bottom: -8px;
  }
  .collar-floor {
    margin-left: 12px;
  }
  .collar-title {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 13px;
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #555555;
    text-align: left;
  }
  .coupon-card {
    margin: 0px 12px 12px;
    background-color: #fff;
    border-radius: 8px;
    .coupon-border {
      border: 1px solid #ebedf0;
      margin-top: 12px;
      .coupon-content {
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        box-sizing: border-box;
        min-height: 84px;
        color: #323233;
        .coupon-head {
          position: relative;
          min-width: 96px;
          color: #ee0a24;
          text-align: center;
        }
        .coupon-amount {
          margin-bottom: -6px;
          span{
            font-weight: 700; 
            font-size: 28px; 
            margin-left: -8px
          }
        }
        .coupon-coudition {
          font-size: 12px;
        }
      }
      .coupon-body {
        position: relative;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        border-radius: 0 8px 8px 0;
        .coupon-name {
          margin-bottom: -5px;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }
        .coupon-time {
          font-size: 12px;
        }
        .coupon-btn {
          position: absolute;
          left: 150px;
          top: 21px;

          button {
            background-color: #ee0a24;
            font-weight: 700;
            font-style: normal;
            border-radius: 50px;
            border: 0;
            width: 81px;
            height: 30px;
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
          }
        }
      }
    }
  }
  .quit {
    .btn {
      display: block;
      width: 100%;
      padding: 0;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
      background: #fff;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
