<!--
 * @Autor: syq
 * @Date: 2021-07-13 14:06:11
-->
<template>
  <div id="detailsfooter">
    <van-goods-action>
      <van-goods-action-icon
        :icon="service_icon"
        text="客服"
        class="gouwucheAddKefu"
        @click="poPKefu"
      />

      <van-goods-action-icon
        v-if="marketCount>0"
        :icon="icon_cart"
        text="购物车"
        @click="toMarket"
        :badge="marketCount"
        class="gouwucheAddCard"
      />
      <van-goods-action-icon
        v-else
        :icon="icon_cart"
        text="购物车"
        @click="toMarket"
        class="gouwucheAddCard"
      />
      <van-goods-action-button
        color="#3DC887"
        text="加入购物车"
        class="gouwucheAdd"
        @click="() => joinMarket(true)"
      />
      <van-goods-action-button
        color="#0095da"
        text="立即购买"
        class="gouwucheAddbuy"
        @click="() => buy(true)"
      />
      <!-- <van-goods-action-button type="danger" text="立即购买" /> -->
    </van-goods-action>
    <van-popup class="popup" v-model="isgift" position="bottom">
      <div class="top-txt">
        <div class="top-txt-1">选择赠品</div>
        <div class="top-txt-2">可选{{giftcount}}件，已选{{chooseNum}}件 </div>
      </div>
      <div id="shopcart">
        <!-- 商品 -->
        <van-checkbox-group v-model="giftData">
        <div class="top" v-for="item in activitygoodinfo" :key="item.id">
          <div class="check">
            <van-checkbox :name="item.id">
              <div></div>
            </van-checkbox>
          </div>
          <!-- 图片 -->
          <div class="left">
            <img :src="item.mediaList?item.mediaList[0].url:''" alt="" />
          </div>
          <!-- 名字，价格 -->
          <div class="right">
            <div class="right-content">
              <div class="right-name" >{{ item.name }}</div>
              <div class="right-sku">
                <div class="choose-item" v-for="i in item.skuList" :key="i.id">
                    <a  :class="{active: i.id === selectedGiftSkuId }" @click="check(i)">{{i.name}}</a>
                </div>
              </div>
              <div class="right-choose">
                <div class="right-price">
                   ￥0.00
                </div>
                <van-stepper style="display:inline-block" @change="checkNum(giftData)" v-model="item.skuQuantity" input-width="20px" button-size="20px" />

                
              </div>
              
            </div>
          </div>
        </div>
        </van-checkbox-group>
        <!-- 底部按钮 -->
        <div class="f_h"></div>
        <div class="footer">
            <div class="content">
              <button class="footer-btn1" @click="tiaoguo">跳过</button>
              <button
                :class="stock() ? 'footer-btn3' : 'footer-btn2'"
                :disabled="stock()"
                @click="ok"
              >
                确认
              </button>
            </div>
        </div>
      </div>
    </van-popup>
    <van-popup class="popup" v-model="isShow" position="bottom">
      <!-- <Shopcart
        :skuList="info.skuList"
        :storeCode="findStoreCode(info)"
        @add="add"
        @close="joinMarket"
      /> -->
      <div id="shopcart">
        <!-- 商品 -->
        <div class="top">
          <!-- 图片 -->
          <div class="left">
            <img :src="itemImage" alt="" />
          </div>
          <!-- 名字，价格 -->
          <div class="right">
            <div class="right-content">
              <p class="right-name">{{ itemname }}</p>
              <p class="right-price">{{ `¥${itemPrice}` }}</p>
            </div>
          </div>
        </div>
        <!-- 数量 -->
        <div class="stepper">
          <van-field name="stepper" label="选择购买数量">
        <template #input>
          <van-stepper v-model="stepper" />
        </template>
          </van-field>
        </div>
        <!-- 规格 -->
        <div class="choose-btn" v-if="itempropertyName.length">
          <div class="choose-title">规格：</div>
             <div class="choose-item">
               <a
                 v-for="s in itempropertyName"
                 :key="s.id"
                 :class="{ active: s.id === (selectedSkuId || defaultSelectedSku) }"
                 @click="() => selectSkuItem(s)"
               >
                 {{ s.name }}
               </a>
             </div>
        </div>
        <!-- 底部按钮 -->
        <div class="f_h"></div>
        <div class="footer">
            <div class="content">
              <button class="footer-btn1" @click="handleCancel">取消</button>
              <button
                :class="stock() ? 'footer-btn3' : 'footer-btn2'"
                :disabled="stock()"
                @click="handleOk"
              >
                {{ stock() ? "已售罄" : "确认" }}
              </button>
            </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import service_icon from "../../../assets/icon/service_icon.svg";
import icon_cart from "../../../assets/icon/icon_cart.svg";
import Shopcart from "./shopcart.vue";
import { addGoodsToMarket, buyNow, queryMarketCount,BatchAddCartLine } from "../service";
import {Toast} from "vant"
export default {
  props: {
    info: Object,
    currentSkuItem: Object,
    currentSkuItemChange: Function,
  },
  components: { Shopcart },
  data() {
    return {
      isShow: false,
      isgift:false,
      isbuy:false,
      marketCount: 0,
      sku: {
        tree: [],
        list: [],
        price: "1.00", // 默认价格（单位元）
        collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
        hide_stock: false, // 是否隐藏剩余库存
      },
      goods: {
        picture: "https://img01.yzcdn.cn/1.jpg",
      },
      service_icon,
      icon_cart,
      stepper:1,
      selectedSkuId:undefined,
      data:[],
      activitygoodinfo:[],
      num:0,
      giftrang:'',
      giftcount:'',
      giftData:[],
      activityNo:'',
      selectedGiftSkuId:'',
      chooseNum:0,
    };
  },
  computed: {
    itemImage: function () {
      const { mediaList } = this.currentSkuItem;
      return mediaList
        ? mediaList[0]?.url
        : "http://uat-oss-business-middle-platform.oss-cn-shenzhen.aliyuncs.com/202108202047596941428700340054843393.png?Expires=1944823679&OSSAccessKeyId=LTAI5tMBSdRFFsKwy54k3F3J&Signature=c%2B%2FQNCjX6kK7mi1wrIZlpdJjCMg%3D";
    },

    itemPrice: function () {
      const { salePrice } = this.currentSkuItem;
      return salePrice?.toFixed(2) ?? 0;
    },

    itemname: function () {
      const { name } = this.currentSkuItem;
      return name ?? 0;
    },

    itempropertyName: function () {
      const {skuList} = this.info
      const arr = []
      Array.isArray(skuList)&&skuList.forEach(i=>{
           if(i?.propertyValueList&&Array.isArray(i?.propertyValueList)){
             let item = '';
             i.propertyValueList.forEach(j=>{
               if(j.propertyType === 2){
                 item = item + `${j.value} `
               }
             })
             item&&arr.push({id:i.id,name:item})
           }
      })
      return arr
    },

    defaultSelectedSku: function () {
      const { id } = this.currentSkuItem;
      return id
    },
    storeCode:function () {
       return  this.info?.realStoreResDto&&this.info.realStoreResDto?.code ? this.info.realStoreResDto.code : undefined
    },
  },
   watch:{
    giftData:{
      handler(newValue,oldValue){
        this.checkNum(newValue)
      },
      immediate: true
    }
  },
  methods: {
    //选择赠品SKU
    check(i){
      console.log(i.id);
      this.selectedGiftSkuId = i.id
    },
    //选择完赠品后跳转下单页
    async ok() {
      let params = this.checkNum(this.giftData)
      const res = await BatchAddCartLine(params)
      if(res.status === "0"){
        this.modal1 = false;
            this.$router.push({
              path: "/placeorder1"
            });
      }else{
        Toast(res.msg)
      }
    },
    checkNum(idArr){
      this.chooseNum = 0
        let activitygoodinfo2 = []
        idArr.map(v=>{
          let aaa = Object.assign([],this.activitygoodinfo)
          aaa.map(item=>{
            if(item.id==v){
              let ttt = {itemCode:item.itemCode,
                skuCode:this.selectedGiftSkuId? this.selectedGiftSkuId : item.skuList[0].skuCode,
                skuQuantity: item.skuQuantity ? item.skuQuantity : 1,
                exchangeItem: true,
                activityNo: this.activityNo,
                mark: 'template',
                storeCode:this.info.storeCode,
                sellerCode: '1',
                cartType: 0,
                isChoice: 1,
                }
              activitygoodinfo2.push(ttt)
            }
          })
        })
        activitygoodinfo2.forEach(item=>{
          this.chooseNum =  this.chooseNum + item.skuQuantity
        })
        if(this.chooseNum>this.giftcount){
          Toast("超出赠送上限,请重新选择")
          this.chooseNum = 0
          this.giftData = []
        }
        return activitygoodinfo2
    }, 
    // 客服
    poPKefu() {
      window.kefuButton.click();
    },
    // 加入购物车弹窗
    joinMarket(flag) {
      this.isShow = flag;
    },
    buy(flag){
      this.isShow = flag;
      this.isbuy = true;
    },
    // 去购物车
    toMarket() {
      this.$router.push({ path: "/market" });
    },

    // 添加商品
    async add(params) {
      const res = await addGoodsToMarket(params);
      if (res.status === "0") {
        Toast("加入成功");
        this.isShow = false;
        this.getMarketCount();
      } else {
        Toast(res.msg);
      }
    },
    //跳过赠品选择
    tiaoguo(){
      this.$router.push({
              path: "/placeorder1"
            });
    },
    //立即购买
     async buynow(params) {
       this.info.activityList.forEach(item => {
          if(item.activityType == 8 ){
            this.activityNo = item.activityNo
          }
      });
      const res = await buyNow(params);
      if (res.status === "0") {
        this.data =  res.data;
        if(res.data.promotions[0].extItems.length>1){
          this.activitygoodinfo = res.data.promotions[0].extItems;
          this.num = res.data.promotions[0].exchangeRuleList[0].exRuleDetailList.length
          this.giftrang = res.data.promotions[0].exchangeRuleList[0].exRuleDetailList[(this.num - 1)].range;
          this.giftcount = res.data.promotions[0].exchangeRuleList[0].exRuleDetailList[(this.num - 1)].number;
          this.isgift =true
        }
        else{
           this.$router.push({
          path:"/placeorder1",
          });
            this.getMarketCount();
        }  
      } else {
        Toast(res.msg);
      }
    },
    // 获取购物车数量
    async getMarketCount() {
      const res = await queryMarketCount();
      if (res.status === "0") {
        this.marketCount = res.data;
      } else {
        Toast(res.msg);
      }
    },
    
    // 判断库存
    stock() {
      return this.currentSkuItem.stock === 0
    },

    // 选择Sku
    selectSkuItem(item) {
      this.selectedSkuId = item.id;
      this.$emit('currentSkuItemChange',this.info.skuList.find(i=>i.id===item.id))  
    },

    // 取消弹窗
    handleCancel() {
      this.joinMarket(false);
    },
    // buyerCode:string 买家code
    // cartType	integer($int32) 购物车类型(默认0-普通)
    // channelId	integer($int32) 渠道id
    // classCode	string 品类编码
    // isChoice	integer($int32) 是否勾选 1 勾选 0取消
    // itemCode	string 商品code
    // packingUnit	string 装箱单位
    // sellerCode	string 卖家code
    // skuCode	string sku编码
    // skuQuantity	integer($int32) sku数量
    // storeCode	string 店铺编码
    // taxRate	string 税率
    // taxRateCode	string 税率编码
    handleOk() {
      const params = {
        storeCode: this.storeCode,
        sellerCode: "1",
        cartType: 0,
        classCode: this.currentSkuItem.classCode,
        isChoice: 1,
        itemCode: this.currentSkuItem.itemCode,
        // packingUnit:''
        skuCode: this.currentSkuItem.skuCode,
        skuQuantity: this.stepper,
      };
      if(this.isbuy){
        this.buynow(params)
      }else{
        this.add(params)
      }
      
    },
  },
  created() {
    this.getMarketCount();
  },
};
</script>

<style lang="less">
#detailsfooter {
  // height: 85px;
  .van-goods-action {
    height: 48px;
    border-top: 1px solid #e9e9e9;
  }
  .gouwucheAdd {
    border-radius: 12px 0 0 12px;
    width: 116px;
    height: 40px;
    // margin-right: 8px;
  }
  .gouwucheAddbuy{
    border-radius: 0 12px 12px 0;
    width: 116px;
    height: 40px;
    margin-right: 16px;
  }
  .gouwucheAddCard {
    margin-right: 15px;
  }

  .gouwucheAddKefu {
    margin-left: 8px;
  }
  .van-goods-action-icon__icon {
    font-size: 20px;
  }
  #shopcart {
  width: 100%;
  .top {
    width: 100%;
    display: flex;
    overflow: hidden;
    .left {
      width: 80px;
      padding: 10px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        border: 1px solid #e9e9e9;
      }
    }
    .right {
      
    }
    .right-content {
      width: 100%;
      padding-left: 22px;
      padding-right: 8px;
      .right-name {
        color: #000;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
         padding: 6px 0;
        height: 45px;
        font-size: 14px;
      }
      .right-sku{
        .choose-item{
            overflow: scroll;
            width: 256px;
            height: 30px;
            padding: 2px 0;
          a{
            border: 1px solid #858585 ;
            border-radius: 2px;
            margin-right: 5px;
            margin-top:2px;
            font-size: 14px;
          }
          .active{
            border-color:red
          }
          
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;

        color: #000;
      }

      .right-price {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        color: #ff3228;
        margin: 0;
        display: inline-block;
        width: 150px;
      }
      .right-choose-icon{
        margin-left: 25px;
      }
    }
  }
  .van-cell {
    padding-right: 0;
  }
  .stepper {
    .van-field__value {
      display: flex;
      justify-content: flex-end;
      padding-right: 20px;
    }
  }
  .choose-btn {
    width: 100%;
    .choose-title {
      width: 100%;
      padding: 0 0 8px 15px;
      font-size: 14px;
      font-weight: 400;
      color: #555555;
    }
    .choose-item {
      width: 100%;
      padding: 0 0 8px 15px;
      display: flex;
      a {
        margin: 0 8px 0 0;
        padding: 4px 8px;
        font-size: 12px;
        border: 1px solid #ccc;

      }
      .active {
        border-color: red;
      }
    }
  }

  .f_h {
    height: 70px;
  }
  .footer {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    .content {
      display: flex;
      justify-content: space-around;
      padding: 0 13px 20px;
      button {
        color: #ffffff;
        font-weight: 700;
        font-size: 13px;
        width: 50%;
        height: 60px;
        border: 0;
      }
      .footer-btn1 {
        width: 160px;
        height: 46px;
        border: 1px solid #0095da;
        border-radius: 12px;
        background: #fff;
        color: #0095da;
      }
      .footer-btn2 {
        width: 160px;
        height: 46px;
        border: 1px solid #0095da;
        border-radius: 12px;
        background: #0095da;
        color: #fff;
      }
      .footer-btn3 {
        width: 160px;
        height: 46px;
        border: 1px solid #a7a7a7;
        border-radius: 12px;
        background-color: #a7a7a7;
      }
    }
  }

  .van-stepper__input {
    font-family: "Montserrat", sans-serif;
  }
}
}
.top-txt{
  font-size: 14px;
  padding-top: 12px;
  &-1{
    display: inline-block;
    width: 247px;
    padding-left: 12px;
  }
  &-2{
    display: inline-block;
  }
}
.right-choose{
  display: flex;
}
.check{
    padding-top: 35px;
    margin-left: 5px;
}
</style>
