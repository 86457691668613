<template>
  <div id="detailscenter">
    <div class="top">
      <div class="left">
        <img :src="findlogo(info)" alt="" />
      </div>
      <div class="right">
        <div class="right-top">
          <p>{{ storeInfo ? storeInfo.name : "" }}</p>
          <a @click="() => jumpstore(info)">
            进入店铺
            <img :src="forward_icon_Inno" />
          </a>
        </div>
        <div class="right-content">
          <p>{{ storeInfo ? storeInfo.description : "" }}</p>
        </div>
      </div>
    </div>

    <div class="details">
      <van-tabs class="tabs_warp">
        <van-tab title="图文详情">
          <div class="detailsImg" v-html="`${this.imgText || '暂无'}`"></div>
        </van-tab>
        <div class="line"></div>
        <van-tab title="规格参数">
          <div class="detais-title">
            <p v-for="item in propertyValueList || []" :key="item.id">
              <span>{{
                `${item.propertyName || "基础信息"}：${item.value || "暂无"}`
              }}</span>
            </p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import forward_icon_Inno from "../../../assets/icon/forward_icon_Inno.svg";
export default {
  props: {
    info: Object,
    currentSkuItem: Object,
  },
  data() {
    return {
      forward_icon_Inno,
    };
  },
  computed: {
    storeInfo: function () {
      const { realStoreResDto } = this.info;
      return realStoreResDto;
    },
  
    imgText: function () {
      const { imageText } = this.info;
      return this.escapeStringHTML(imageText);
    },
    propertyValueList: function () {
      const { propertyValueList } = this.info;
      return propertyValueList? propertyValueList.filter((item) => item.propertyType === 1): [];
    },
  },
  methods: {
    //店铺logo
      findlogo(item){
        return  item?.realStoreResDto?.ext?JSON.parse(JSON.parse(item.realStoreResDto.ext).logo)[0]?.thumbUrl : 'http://www.liby.com.cn/images/logo.png'
    },
    escapeStringHTML(str) {
      if (!str) return "";
      str = str.replace(/&lt;/g, "<");
      str = str.replace(/&gt;/g, ">");
      str = str.split("&quot;").join(" ");
      str = str.replace(/<img/g, "<img style='width: 375px'");
      return str;
    },
    jumpstore(item) {
      this.$router.push({
        path: "/storehome",
        query: { storeCode: item.storeCode },
      });
    },
  },
};
</script>

<style lang="less">
#detailscenter {
  margin-top: 8px;
  width: 100%;
  .top {
    width: 100%;
    padding: 12px 12px;
    display: flex;
    background-color: #fff;
    .left {
      img {
        width: 60px;
        height: 60px;
        border-radius: 4px;
        border: 1px solid #e9e9e9;
      }
    }
    .right {
      flex: 1;
      .right-top {
        width: 100%;
        height: 20px;
        padding: 0 0 0 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 70%;
        }
        a {
          color: #ff9e48;
          height: 18px;
          font-size: 12px;
        }

        img {
          width: 12px;
          height: 12px;
          position: relative;
          top: 2px;
        }
      }
      .right-content {
        width: 100%;
        padding: 0 0 0 8px;
        p {
          margin: 0;
          font-size: 12px;
          color: #666;
        }
      }
    }
  }
  .details {
    margin: 8px 0 8px 0;
    background-color: #fff;

    .detailsImg {
      padding: 16px;
      p {
        margin: 8px 0;
        font-size: 12px;
        line-height: 18px;
        color: #7f7f7f;
      }
        img{
          width: 100% !important;
      }
    }
    .detais-title {
      padding: 16px;
      color: #7f7f7f;
      text-align: left;
      line-height: 18px;
      font-size: 12px;
      p {
        margin: 0;
      }
    }
  }

  .tabs_warp {
    .line {
      width: 1px;
      height: 14px;
      background: #e9e9e9;
      left: 50%;
      top: 12.5px;
      position: absolute;
    }
  }
}
.van-tabs__line {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 0;
  width: 40px;
  height: 3px;
  border-radius: 3px;
  display: none;
  color: #000;
}

.van-tab--active {
  color: #0095da !important;
}

.van-tabs__wrap {
  border-bottom: 1px solid #e9e9e9;
  position: relative;
}
</style>
